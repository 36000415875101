import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import SEO from "../components/seo"

import ScrollAnimation from 'react-animate-on-scroll';

import ImageCoaching from '../components/images/imageCoaching.js'
import ImageDevelopement from '../components/images/imageDevelopement.js'
import ImageClimat from '../components/images/imageClimat.js'
import ImageChange from '../components/images/imageChange.js'
import ImageConflit from '../components/images/imageConflit.js'
import ImageLeft1 from '../components/images/imageLeft1.js'

const GestionChangement = () => (
  <Layout>
    <SEO title="À propos | Gestion Mieux-Être" />
    <PageHeader
        header="À propos de nous"
        text="Depuis 2007, GESTION MIEUX-ÊTRE vous propose des solutions personnalisées contribuant au succès de votre entreprise.  Nous vous aidons à rendre votre environnement de travail plus humain et inspirant."
        quote="En s’appuyant sur la mise en place de saines pratiques, nous pouvons contribuer activement à l’atteinte de vos succès.  <span>- Gestion mieux-être</span>"
        background="mieuxetre" />
    <div className="main-wrapper pad-page tcenter">
        <ScrollAnimation
            delay="0"
            animateIn="fadeIn">

        <p class="service-presentation2">Notre nom, GESTION MIEUX-ETRE, démontre clairement notre volonté de construire avec vous des environnements de travail qui soient à la fois dynamiques et équilibrés dans lesquels tous s’épanouissent et contribuent énergiquement au succès collectif. <br /><br />Nous avons bâti notre réputation et notre modèle d’affaires sur nos valeurs d’entreprise.  Nous misons sur :</p>
        <div className="flex-container flex-start pad2">
            <div className="third">
                <div className="about-wrapper">
                    <p><span>Authenticité</span>:  Nous plaçons les relations humaines au centre de nos accompagnements.  Tablant sur une sincère complicité, nous souhaitons développer et maintenir avec vous un partenariat significatif et pérenne qui reflète bien notre engagement.</p>
                </div>
            </div>
            <div className="third">
                <div className="about-wrapper brain">
                    <p><span>Ingéniosité</span>:  Chaque situation est unique.  Nos interventions sont éminemment sensibles à votre culture et votre réalité.  Nous vous offrons des solutions alignées sur vos besoins d’affaires qui sont à la fois pragmatiques, créatives et personnalisées.</p>
                </div>
            </div>
            <div className="third">
                <div className="about-wrapper book">
                    <p><span>Rigueur</span>: Nous adhérons aux pratiques professionnelles les plus élevées. Le professionnalisme, l’intégrité et l’éthique sont au centre de tous nos accompagnements.</p>
                </div>
            </div>
        </div>
        <p class="service-presentation2">Grâce à notre expertise chevronnée, nous captons l’essentiel afin de provoquer les résultats durables.  Nous établissons avec vous les meilleures solutions pour créer l’environnement de travail humain que vous souhaitez.  <br /><br />Notre ambition, vous soutenir dans votre parcours vers le succès !   Nous souhaitons faire une réelle différence dans votre vie professionnelle, et ce, en ciblant des objectifs audacieux. </p>
        <Link className="button-link" to="/nous-joindre">Nous joindre</Link>
        </ScrollAnimation>
    </div>
  </Layout>
)

export default GestionChangement
